import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { Mixins } from "vue-property-decorator";
import KmFormatted from "@/mixins/Project/KilometrageFromatted";
import i18n from "@/i18n.ts";
import Section from "./ModelSection";
import { ProjectProfile } from "@/interface/projectsInterface";

interface SectionInfo {
  Name: string;
  Kilometer: string;
  IDLinkeSeite: string;
  IDRechteSeite: string;
}

interface displayCharacter {
  label: string;
  image: string;
  info: string;
  extraInfo: string;
}

interface displayCharacterRoadZipper {
  label: string;
  imageScene1: string;
  imageScene2: string;
  imageScene3: string;
  imageSceneS: string;
}
export interface NumberOfUsedDevicesInProject {
  numberOfLargeDisplay: number;
  numberOfMediumDisplay: number;
  numberOfSmallDisplay: number;
  numberOfMeasure: number;
  numberOfCamera: number;
}

interface TableStructureData {
  tableTitle: any | null;
  tableHeader: RowInput[] | any;
  tableBody: any | null;
  tableFooter: RowInput[] | any;
}

export default class ModelPdfReport extends Mixins(KmFormatted) {
  switchingStateItemsPdfReport: RowInput[] = [];
  sectionInfoItemsPdfReport: SectionInfo[] | any[] = [];
  oppositeSectionInfoItemsPdfReport: SectionInfo[] | any[] = [];

  // default size of images in MSWA projects
  displayCharactersImgwidth = "64";
  displayCharactersImgheight = "96";

  webUrl = process.env.VUE_APP_WEB_URL;

  preparePdfReport(generatingReport: any, projectType: any, GetSectionList: any, GetAllOppositeSections?: any) {
    // generate date of section and switching state tables and get the right size of display characters according to type of project
    switch (projectType) {
      case "roadzipper":
        for (let sectionIndex = 0; sectionIndex < GetSectionList.length; sectionIndex++) {
          const title = GetSectionList[sectionIndex].description || GetSectionList[sectionIndex].title;
          const Kilometer = GetSectionList[sectionIndex].kilometrage;
          const IDLinkeSeite =
            GetSectionList[sectionIndex].type.name === "display"
              ? GetSectionList[sectionIndex].boardOne
              : GetSectionList[sectionIndex].boardTwo;
          const IDRechteSeite =
            GetSectionList[sectionIndex].type.name === "display"
              ? GetSectionList[sectionIndex].boardTwo
              : GetSectionList[sectionIndex].boardOne;
          const sectionInfoRow = {
            Name: title,
            Kilometer: Kilometer,
            IDLinkeSeite: IDLinkeSeite,
            IDRechteSeite: IDRechteSeite
          };

          this.sectionInfoItemsPdfReport.push(sectionInfoRow);
        }
        for (let sectionIndex = 0; sectionIndex < GetAllOppositeSections.length; sectionIndex++) {
          const title = GetAllOppositeSections[sectionIndex].description || GetAllOppositeSections[sectionIndex].title;
          const Kilometer = GetAllOppositeSections[sectionIndex].kilometrage;
          const IDLinkeSeite =
            GetAllOppositeSections[sectionIndex].type.name === "display"
              ? GetAllOppositeSections[sectionIndex].boardOne
              : GetAllOppositeSections[sectionIndex].boardTwo;
          const IDRechteSeite =
            GetAllOppositeSections[sectionIndex].type.name === "display"
              ? GetAllOppositeSections[sectionIndex].boardTwo
              : GetAllOppositeSections[sectionIndex].boardOne;
          const sectionInfoRow = {
            Name: title,
            Kilometer: Kilometer,
            IDLinkeSeite: IDLinkeSeite,
            IDRechteSeite: IDRechteSeite
          };

          this.oppositeSectionInfoItemsPdfReport.push(sectionInfoRow);
        }
        break;

      case "free_programmable":
        break;

      case "variable_message_signs":
        // default size of images in WVZ projects
        this.displayCharactersImgwidth = "48";
        this.displayCharactersImgheight = "48";
        // Size of images equals display size of right side of section 0
        if (GetSectionList[0].displayTwoType as any) {
          this.displayCharactersImgwidth = (GetSectionList[0].displayTwoType as any).width;
          this.displayCharactersImgheight = (GetSectionList[0].displayTwoType as any).height;
        }
        break;

      // MSWA
      default:
        // add contents to section table
        for (let sectionIndex = 0; sectionIndex < GetSectionList.length; sectionIndex++) {
          // Section table info
          const title = GetSectionList[sectionIndex].description || GetSectionList[sectionIndex].title;
          const Kilometer = GetSectionList[sectionIndex].kilometrage;
          // Measure device is always as board one and in the right side
          const IDLinkeSeite =
            GetSectionList[sectionIndex].type.name === "display"
              ? GetSectionList[sectionIndex].boardOne
              : GetSectionList[sectionIndex].boardTwo;
          const IDRechteSeite =
            GetSectionList[sectionIndex].type.name === "display"
              ? GetSectionList[sectionIndex].boardTwo
              : GetSectionList[sectionIndex].boardOne;
          const sectionInfoRow = {
            Name: title,
            Kilometer: Kilometer,
            IDLinkeSeite: IDLinkeSeite,
            IDRechteSeite: IDRechteSeite
          };

          this.sectionInfoItemsPdfReport.push(sectionInfoRow);
          // MSWA projects: Size of images equals display size of right side of AQ1
          if (GetSectionList[sectionIndex].title === "AQ1") {
            this.displayCharactersImgwidth = (GetSectionList[sectionIndex].displayTwoType as any).width;
            this.displayCharactersImgheight = (GetSectionList[sectionIndex].displayTwoType as any).height;
          }
        }
        break;
    }

    //convert csv style to array of objects to fill table of switching states
    if (generatingReport.data?.length > 0) {
      const array = generatingReport.data.toString().split("\n");
      const length = array.length;
      for (let index = 1; index < length; index++) {
        if (projectType === "roadzipper") this.switchingStateItemsPdfReport.push(array[index].split(";").slice(0, 9));
        else this.switchingStateItemsPdfReport.push(array[index].split(";").slice(0, 8));
      }
    }
    return [
      {
        sectionInfo: this.sectionInfoItemsPdfReport,
        oppositeSectionInfo: this.oppositeSectionInfoItemsPdfReport,
        imageWidth: this.displayCharactersImgwidth,
        imageHeight: this.displayCharactersImgwidth,
        switchingState: this.switchingStateItemsPdfReport
      }
    ];
  }

  checkTextSize(pdf: jsPDF, text: string, fontSize: number, fontStyle: string) {
    const font = "Helvetica";
    pdf.setFontSize(fontSize);
    pdf.setFont(font, fontStyle);
    //calculate text width after change style and font to get correct value
    return pdf.getTextWidth(text);
  }

  translate(text: string) {
    return i18n.t(text).toString();
  }
  drawText(
    pdf: jsPDF,
    text: string,
    textColor: number,
    fontSize: number,
    fontStyle: string,
    xPosition: number,
    yPosition: number,
    relativePosition: string
  ) {
    // This function draw all text in the pdf report
    // pdf: pdf Object which work on
    // text: text to write
    // textColor: color in decimal
    // fontSize: number
    // fontStyle: 'bold', 'normal', ...
    // xPosition, yPosition: the position of start writing this text
    // relativePosition: discard the value of xPosition and has much cases:
    //                    'middle'     : put the text in the middle of page
    //                    'fromMiddle' : start writing from the middle of page

    // default font of Text
    const font = "Helvetica";
    if (pdf) {
      const pageSizeWidth = pdf.internal.pageSize.getWidth();
      const pageSizeHeight = pdf.internal.pageSize.getHeight();

      pdf.setTextColor(textColor);
      pdf.setFontSize(fontSize);
      pdf.setFont(font, fontStyle);
      //calculate text width after change style and font to get correct value
      const textSize = pdf.getTextWidth(text);

      // discard xPosition if we pass one of relativPosition cases
      switch (relativePosition) {
        case "middle":
          xPosition = pageSizeWidth / 2 - textSize / 2;
          break;
        case "fromMiddle":
          text = pdf.splitTextToSize(text, 3.5);
          xPosition = pageSizeWidth / 2;
          break;
        case "untilMiddle":
          xPosition = pageSizeWidth / 2 - 1.63; //1.49
          break;
        case "footerLeft":
          xPosition = pageSizeWidth / 2 - 2.9;
          break;
        case "footerMiddle":
          text = pdf.splitTextToSize(text, 1.98);
          xPosition = pageSizeWidth / 2 - 0.675;
          break;
        case "footerRight":
          xPosition = pageSizeWidth / 2 + 1.35;
          break;
        case "pageNumber":
          xPosition = pageSizeWidth - 1;
          yPosition = pageSizeHeight - 0.3;
          break;
      }
      pdf.text(text, xPosition, yPosition);
    }
    return pdf;
  }

  drawFirstPagePdfReport(pdf: jsPDF | any, projectInfoValues: any, projectInfo: any, reportType: string) {
    pdf.setPage(1);

    this.drawPageForm(pdf, reportType, projectInfo);

    const reportTitle = this.translate(`reports.${reportType}.reportName`);

    this.drawText(pdf, reportTitle, 0, 26, "bold", 0, 1.85, "middle");

    const projectInfoLabels = [
      this.translate("reports.switchingStates.projectName"),
      this.translate("reports.switchingStates.direction"),
      this.translate("reports.switchingStates.projectType"),
      this.translate(`reports.${reportType}.period`),
      ""
    ];

    let infoYPosition = 3.3;
    for (let projectInfoIndex = 0; projectInfoIndex < 5; projectInfoIndex++) {
      const sizeOftext = this.checkTextSize(pdf, projectInfoValues[projectInfoIndex], 16, "normal");

      this.drawText(pdf, projectInfoLabels[projectInfoIndex], 0, 16, "normal", 0, infoYPosition, "untilMiddle");

      this.drawText(pdf, projectInfoValues[projectInfoIndex], 0, 16, "normal", 0, infoYPosition, "fromMiddle");

      infoYPosition =
        sizeOftext > 3.5 ? (sizeOftext > 7 ? infoYPosition + 0.9 : infoYPosition + 0.6) : infoYPosition + 0.3;
    }

    return pdf;
  }

  drawDisplayCharacters(
    pdf: jsPDF | any,
    projectInfo: any,
    reportType: string,
    GetSectionList?: any,
    GetAllOppositeSections?: any
  ) {
    const displayCharactersImagespath = `${this.webUrl}/img/${this.displayCharactersImgwidth}x${this.displayCharactersImgheight}/`;
    // convert to inch
    const displayCharactersImagesWidth = parseInt(this.displayCharactersImgwidth) / 96;
    const displayCharactersImagesheight = parseInt(this.displayCharactersImgheight) / 96;
    let displayCharacters: displayCharacter[] = [];

    let displayCharactersVelDirection: displayCharacterRoadZipper[] = [];
    let displayCharactersWupDirection: displayCharacterRoadZipper[] = [];

    const roadZipperScenes = ["Zustand 1", "Zustand 2", "Zustand 3", "Sonderfall"];

    let displayCharactersTitleYPosition = 3.5;
    let displayCharactersYPosition = 4;
    switch (projectInfo.projectType) {
      case "roadzipper": {
        const displaySections = GetSectionList.filter((item: Section) => item.type?.name === "display");
        const displayOppositeSections = GetAllOppositeSections.filter((item: Section) => item.type?.name === "display");
        displayCharactersWupDirection = [
          {
            label: `AQ6\n${this.formattedPosition(displayOppositeSections[6].kilometrage)}`,
            imageScene1: "Wup_6_1.bmp",
            imageScene2: "Wup_6_2.bmp",
            imageScene3: "Wup_6_3.bmp",
            imageSceneS: "Wup_6_S.bmp"
          },
          {
            label: `AQ5\n${this.formattedPosition(displayOppositeSections[5].kilometrage)}`,
            imageScene1: "Wup_5_1.bmp",
            imageScene2: "Wup_5_2.bmp",
            imageScene3: "Wup_5_3.bmp",
            imageSceneS: "Wup_5_S.bmp"
          },
          {
            label: `AQ4\n${this.formattedPosition(displayOppositeSections[4].kilometrage)}`,
            imageScene1: "Wup_4_1.bmp",
            imageScene2: "Wup_4_2.bmp",
            imageScene3: "Wup_4_3.bmp",
            imageSceneS: "Wup_4_S.bmp"
          },
          {
            label: `AQ3\n${this.formattedPosition(displayOppositeSections[3].kilometrage)}`,
            imageScene1: "Wup_3_1.bmp",
            imageScene2: "Wup_3_2.bmp",
            imageScene3: "Wup_3_3.bmp",
            imageSceneS: "Wup_3_S.bmp"
          },
          {
            label: `AQ2\n${this.formattedPosition(displayOppositeSections[2].kilometrage)}`,
            imageScene1: "60.bmp",
            imageScene2: "60.bmp",
            imageScene3: "40.bmp",
            imageSceneS: "60.bmp"
          },
          {
            label: `AQ1_1\n${this.formattedPosition(displayOppositeSections[1].kilometrage)}`,
            imageScene1: "60.bmp",
            imageScene2: "60.bmp",
            imageScene3: "40.bmp",
            imageSceneS: "60.bmp"
          },
          {
            label: `AQ1\n${this.formattedPosition(displayOppositeSections[0].kilometrage)}`,
            imageScene1: "Wup_1_1.bmp",
            imageScene2: "Wup_1_2.bmp",
            imageScene3: "Wup_1_3.bmp",
            imageSceneS: "Wup_1_S.bmp"
          }
        ];

        displayCharactersVelDirection = [
          {
            label: `AQ6\n${this.formattedPosition(displaySections[0].kilometrage)}`,
            imageScene1: "Vel_6_1.bmp",
            imageScene2: "Vel_6_2.bmp",
            imageScene3: "Vel_6_3.bmp",
            imageSceneS: "Vel_6_S.bmp"
          },
          {
            label: `AQ5\n${this.formattedPosition(displaySections[1].kilometrage)}`,
            imageScene1: "Vel_5_1.bmp",
            imageScene2: "Vel_5_2.bmp",
            imageScene3: "Vel_5_3.bmp",
            imageSceneS: "Vel_5_S.bmp"
          },
          {
            label: `AQ4\n${this.formattedPosition(displaySections[2].kilometrage)}`,
            imageScene1: "Vel_4_1.bmp",
            imageScene2: "Vel_4_2.bmp",
            imageScene3: "Vel_4_3.bmp",
            imageSceneS: "Vel_4_S.bmp"
          },
          {
            label: `AQ3\n${this.formattedPosition(displaySections[3].kilometrage)}`,
            imageScene1: "Vel_3_1.bmp",
            imageScene2: "Vel_3_2.bmp",
            imageScene3: "Vel_3_3.bmp",
            imageSceneS: "Vel_3_S.bmp"
          },
          {
            label: `AQ2\n${this.formattedPosition(displaySections[4].kilometrage)}`,
            imageScene1: "60.bmp",
            imageScene2: "60.bmp",
            imageScene3: "40.bmp",
            imageSceneS: "60.bmp"
          },
          {
            label: `AQ1_1\n${this.formattedPosition(displaySections[5].kilometrage)}`,
            imageScene1: "60.bmp",
            imageScene2: "60.bmp",
            imageScene3: "40.bmp",
            imageSceneS: "60.bmp"
          },
          {
            label: `AQ1\n${this.formattedPosition(displaySections[6].kilometrage)}`,
            imageScene1: "Vel_1_1.bmp",
            imageScene2: "Vel_1_2.bmp",
            imageScene3: "Vel_1_3.bmp",
            imageSceneS: "Vel_1_S.bmp"
          }
        ];

        displayCharactersTitleYPosition = 3.9;
        displayCharactersYPosition = 4.1;
        break;
      }

      case "free_programmable":
        displayCharactersTitleYPosition = 0.8;
        break;

      case "variable_message_signs":
        displayCharactersTitleYPosition = 0.8;

        // Labels, images and info of display characters
        displayCharacters = [
          { label: "", image: "40.bmp", info: "40 Km/h", extraInfo: "" },
          { label: "", image: "60.bmp", info: "60 Km/h", extraInfo: "" },
          { label: "", image: "80.bmp", info: "80 Km/h", extraInfo: "" },
          { label: "", image: "100.bmp", info: "100 Km/h", extraInfo: "" },
          { label: "", image: "120.bmp", info: "120 Km/h", extraInfo: "" }
        ];
        displayCharactersYPosition = 2;
        break;

      // MSWA 1x AQ + 1x MQ - HYST
      case "congestion_warning_aq_6": {
        displayCharactersTitleYPosition = 3.5;
        const { customFilenameOff, customFilenameTraffic, customFilenameEnabled } = GetSectionList.find(
          (s: Section) => s.type?.name === "display"
        )?.state.cwuSettings || {
          customFilenameOff: "noPicture.jpg",
          customFilenameTraffic: "Stau.bmp",
          customFilenameEnabled: false
        };

        // Labels, images and info of display characters
        displayCharacters = [
          {
            label: "Frei/Aus/\nGrundversorgung",
            image: customFilenameEnabled ? customFilenameOff : "noPicture.jpg",
            info: "Vorwarnblinker: Aus",
            extraInfo: "CB-Funk: Aus"
          },
          {
            label: "Stau",
            image: customFilenameEnabled ? customFilenameTraffic : "Stau.bmp",
            info: "Vorwarnblinker: An",
            extraInfo: "CB-Funk: An"
          }
        ];
        displayCharactersYPosition = 4;

        const ImageFormattedName = customFilenameOff.replace(".bmp", "").replace(".jpg", "");
        const fileNamesThatIncludeKMH = ["40", "60", "80", "100", "120"];
        const isImageNameNumber = fileNamesThatIncludeKMH.includes(ImageFormattedName);

        const displayCharactersNotExplicity = `Die Grundversorgung der hier vorliegenden Station / Streckenabschnitts wurde\n${
          customFilenameEnabled ? "mit " + ImageFormattedName : "nicht"
        }${isImageNameNumber ? " km/h" : ""} eingestellt${
          customFilenameEnabled
            ? ", und wird zu jeder Zeit während der Inaktivität der mStWA \ndem Verkehrsteilnehmer angezeigt"
            : ""
        }. Die Abweichungen hierzu und auch das Umschalten \nauf die Grundversorgung wird in der nachfolgenden Tabelle protokolliert.`;
        this.drawText(pdf, displayCharactersNotExplicity, 0, 12, "normal", 1.2, 7.6, "");
        break;
      }

      // MSWA
      default: {
        displayCharactersTitleYPosition = 3.5;

        // Labels, images and info of display characters
        displayCharacters = [
          { label: "Frei/Aus", image: "noPicture.jpg", info: "Vorwarnblinker: Aus", extraInfo: "CB-Funk: Aus" },
          { label: "Staugefahr", image: "Stgefahr.bmp", info: "Vorwarnblinker: An", extraInfo: "CB-Funk: An" },
          { label: "Stau", image: "Stau.bmp", info: "Vorwarnblinker: An", extraInfo: "CB-Funk: An" }
        ];
        displayCharactersYPosition = 4;

        const displayCharactersNotExplicity = "Frei/Aus werden nicht explizit dokumentiert!";
        this.drawText(pdf, displayCharactersNotExplicity, 0, 12, "normal", 1.2, 7.6, "");
        break;
      }
    }

    // Labels, images and info of display characters
    if (projectInfo.projectType !== "roadzipper") {
      // Add new page for display characters of direct direction of WVZ and Free programming projects, in MSWA display characters should be in same page of section table

      if (projectInfo.projectType === "free_programmable" || projectInfo.projectType === "variable_message_signs")
        pdf.addPage();
      pdf.setPage(2);

      this.drawPageForm(pdf, reportType, projectInfo);

      const displayCharactersTitle = this.translate("reports.switchingStates.trafficSignsTitle");
      this.drawText(pdf, displayCharactersTitle, 0, 20, "bold", 0.5, displayCharactersTitleYPosition, "");

      displayCharacters.forEach((element) => {
        this.drawText(pdf, element.label, 0, 12, "normal", 1.2, displayCharactersYPosition, "");

        const displayCharacterImage = new Image();
        displayCharacterImage.src = `${displayCharactersImagespath}${element.image || "noPicture.jpg"}`;
        const isImageExistedInFileServer = this.checkImageIfExist(displayCharacterImage.src);
        pdf.addImage(
          isImageExistedInFileServer ? displayCharacterImage : `${displayCharactersImagespath}noPicture.jpg`,
          "BMP",
          2.7,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );

        this.drawText(pdf, element.info, 0, 12, "normal", 3.9, displayCharactersYPosition, "");
        this.drawText(pdf, element.extraInfo, 0, 12, "normal", 3.9, displayCharactersYPosition + 0.3, "");
        displayCharactersYPosition += 1.2;
      });
    } else {
      // Add new page for display characters of direct direction
      pdf.addPage();
      pdf.setPage(3);

      this.drawPageForm(pdf, reportType, projectInfo);

      let displayCharactersTitle = `Verkehrszeichen Fahrtrichtung ${projectInfo.directionNameStart}`;
      this.drawText(pdf, displayCharactersTitle, 0, 20, "bold", 0.5, 1, "");

      let xPosition = 2;
      roadZipperScenes.forEach((element) => {
        this.drawText(pdf, element, 0, 12, "normal", xPosition, 1.5, "");
        xPosition += 1.5;
      });
      displayCharactersYPosition = 2;
      displayCharactersWupDirection.forEach((element) => {
        this.drawText(pdf, element.label, 0, 10, "normal", 1.2, displayCharactersYPosition, "");

        const displayCharacterImage = new Image();
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene1}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          2,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene2}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          3.5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene3}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageSceneS}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          6.5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharactersYPosition += 1.1;
      });

      // Add new page for display characters of direct direction
      pdf.addPage();
      pdf.setPage(4);

      // Draw header, background and footer
      this.drawPageForm(pdf, reportType, projectInfo);

      // title of display characters
      displayCharactersTitle = `Verkehrszeichen Fahrtrichtung ${projectInfo.directionNameEnd}`;
      this.drawText(pdf, displayCharactersTitle, 0, 20, "bold", 0.5, 1, "");

      xPosition = 2;
      roadZipperScenes.forEach((element) => {
        this.drawText(pdf, element, 0, 12, "normal", xPosition, 1.5, "");
        xPosition += 1.5;
      });
      displayCharactersYPosition = 2;
      displayCharactersVelDirection.forEach((element) => {
        this.drawText(pdf, element.label, 0, 10, "normal", 1.2, displayCharactersYPosition, "");

        const displayCharacterImage = new Image();
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene1}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          2,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene2}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          3.5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageScene3}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharacterImage.src = `${displayCharactersImagespath}${element.imageSceneS}`;
        pdf.addImage(
          displayCharacterImage,
          "PNG",
          6.5,
          displayCharactersYPosition - 0.2,
          displayCharactersImagesWidth,
          displayCharactersImagesheight
        );
        displayCharactersYPosition += 1.1;
      });
    }
    return pdf;
  }
  checkImageIfExist(imageURL: string) {
    try {
      const http = new XMLHttpRequest();
      http.open("HEAD", imageURL, false);
      http.send();
      return http.status != 404;
    } catch (error) {
      return false;
    }
  }

  drawTablePagesPdfReport(pdf: jsPDF | any, projectInfo: any, reportType: string) {
    let head;
    // From Page 3: switching states table
    if (projectInfo.projectType !== "roadzipper") {
      pdf.setPage(3);
      head = [["Datum", "Zeit", "ID", "Name", "Seite", "Position", "Anzeige", "Modus"]];
    }
    // The table will be drawn from 4th page if the project is roadzipper because of display characters of opposite direction in the third page
    else {
      pdf.addPage();
      pdf.setPage(4);
      head = [["Datum", "Zeit", "ID", "Name", "Seite", "Position", "Anzeige", "Zustand", "Fahrtrichtung"]];
    }

    const body = this.switchingStateItemsPdfReport;
    const switchingStateTableTitle = "Schaltzustände";

    // this function add table title to every page
    const header = () => {
      if (pdf) {
        this.drawText(pdf, switchingStateTableTitle, 0, 20, "bold", 0.8, 0.8, "");

        // Draw header, background and footer
        this.drawPageForm(pdf, reportType, projectInfo);
      }
    };

    // table of switching states
    autoTable(pdf, {
      head: head,
      body: body,
      startY: 1,
      pageBreak: "always",
      styles: { fontSize: 8, textColor: 0 },
      headStyles: { fillColor: [49, 124, 60], textColor: 255 },
      margin: { top: 1.2, bottom: 1 },
      didDrawPage: header
    });

    return pdf;
  }

  drawPageForm(pdf: jsPDF | any, reportType: string, projectInfo: any) {
    // Draw report form: logo, background, footer
    const logoImage = new Image();
    logoImage.src = "/img/brand/logo-ibomade.png";
    const backgroundLogoImage = new Image();
    backgroundLogoImage.src = "/img/brand/logo-ibomade-small-background.png";
    const footerDirection =
      projectInfo.projectType === "roadzipper"
        ? `${projectInfo.directionNameStart}/${projectInfo.directionNameEnd}`
        : `${projectInfo.directionNameEnd}`;
    const footerIbomadeName = "IBOMADE GmbH";
    const footer: { left: string; middle: string; right: string }[] = [
      {
        left: "4. Industriestraße 16",
        middle: this.translate(`reports.${reportType}.reportName`),
        right: this.translate("reports.switchingStates.documentationInfo")
      },
      {
        left: "68766 Hockenheim",
        middle:
          reportType === "projectList"
            ? ""
            : `${this.translate("reports.switchingStates.direction")} ${footerDirection}`,
        right: `${this.translate("reports.switchingStates.createdOn")} ${i18n.d(new Date(), "repShort", "de")}`
      },
      {
        left: "www.ibomade-technik.de",
        middle: projectInfo.projectName,
        right: `${this.translate("reports.switchingStates.createdBy")} LVMS-Manager`
      }
    ];
    const pageHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(logoImage, "PNG", 6, 0.3, 1.5, 0.4);
    pdf.addImage(backgroundLogoImage, "PNG", 4.8, 4.5, 5, 5.3);
    let yPosition = pageHeight - 0.9;
    this.drawText(pdf, footerIbomadeName, 0, 10, "bold", 0, yPosition, "footerLeft");
    yPosition += 0.2;
    footer.forEach((element: { left: string; middle: string; right: string }) => {
      this.drawText(pdf, element.left, 0, 10, "normal", 0, yPosition, "footerLeft");
      this.drawText(pdf, element.middle, 0, 10, "normal", 0, yPosition, "footerMiddle");
      this.drawText(pdf, element.right, 0, 10, "normal", 0, yPosition, "footerRight");
      yPosition += 0.2;
    });
  }

  drawPageNumber(pdf: jsPDF | any) {
    // Report form: page number
    // the number of pages and create date at the bottom
    const numberOfPages = pdf.getNumberOfPages();
    for (let i = 1; i <= numberOfPages; i++) {
      pdf.setPage(i);
      // Page number
      this.drawText(
        pdf,
        `${i18n.t("reports.switchingStates.pageNumber", { pageNumber: i, totalNumber: numberOfPages })}`,
        150,
        8,
        "normal",
        0,
        0,
        "pageNumber"
      );
    }
    return pdf;
  }

  drawReportFormPdfReport(
    pdf: jsPDF | any,
    reportType: string,
    createDate: string,
    projectType?: any,
    directionNameStart?: string,
    directionNameEnd?: string
  ) {
    const logoImage = new Image();
    logoImage.src = "/img/brand/logo-ibomade.png";
    const backgroundLogoImage = new Image();
    backgroundLogoImage.src = "/img/brand/logo-ibomade-small-background.png";
    const numberOfPages = pdf.getNumberOfPages();
    const footerIbomadeName = "IBOMADE GmbH";
    // Footer for two types of reports: one is Schaltzustandübersicht (middle) and the other is Projects overview (middleProjectList)
    const footer: { left: string; middle: string; middleProjectList: string; right: string }[] = [
      {
        left: "4. Industriestraße 16",
        middle: "Schaltzustandsbericht",
        middleProjectList: "Projektübersicht",
        right: "Dokumenteninformation"
      },
      {
        left: "68766 Hockenheim",
        middle: `Fahrtrichtung: ${directionNameEnd}`,
        middleProjectList: "",
        right: `Erstellt am: ${createDate}`
      },
      { left: "www.ibomade-technik.de", middle: "", middleProjectList: "", right: "Ersteller: LVMS-Manager" }
    ];
    if (projectType === "roadzipper") footer[1].middle = `Fahrtrichtung: ${directionNameStart}/${directionNameEnd}`;
    const pageHeight = pdf.internal.pageSize.getHeight();
    for (let i = 1; i <= numberOfPages; i++) {
      pdf.setPage(i);
      pdf.addImage(logoImage, "PNG", 6, 0.3, 1.5, 0.4);
      if (!((i === 3 || i === 4) && projectType === "roadzipper"))
        pdf.addImage(backgroundLogoImage, "PNG", 4.8, 4.5, 5, 5.3);

      let yPosition = pageHeight - 0.9;
      this.drawText(pdf, footerIbomadeName, 0, 10, "bold", 0, yPosition, "footerLeft");
      yPosition += 0.2;
      footer.forEach((element: { left: string; middle: string; middleProjectList: string; right: string }) => {
        this.drawText(pdf, element.left, 0, 10, "normal", 0, yPosition, "footerLeft");
        if (reportType === "projectList")
          // print middle part of footer of Project list report
          this.drawText(pdf, element.middleProjectList, 0, 10, "normal", 0, yPosition, "footerMiddle");
        // print footer of project switching states report
        else this.drawText(pdf, element.middle, 0, 10, "normal", 0, yPosition, "footerMiddle");
        this.drawText(pdf, element.right, 0, 10, "normal", 0, yPosition, "footerRight");
        yPosition += 0.2;
      });

      this.drawText(pdf, `Seite ${i} von ${numberOfPages}`, 150, 8, "normal", 0, 0, "pageNumber");
    }
    return pdf;
  }

  // project overview rerport
  calculateNumberOfUsedDevicesInSubProject(
    sectionInfo: any,
    numberOfUsedDevicesInSubProject: NumberOfUsedDevicesInProject
  ) {
    for (let s = 0; s < sectionInfo.length; s++) {
      switch (sectionInfo[s].type.name) {
        case "display":
          if (sectionInfo[s].boardOne) {
            switch (sectionInfo[s].displayOneType?.name) {
              case "large":
                numberOfUsedDevicesInSubProject.numberOfLargeDisplay += 1;
                break;
              case "medium":
                numberOfUsedDevicesInSubProject.numberOfMediumDisplay += 1;
                break;
              case "small":
                numberOfUsedDevicesInSubProject.numberOfSmallDisplay += 1;
                break;
            }
          }
          if (sectionInfo[s].boardTwo) {
            switch (sectionInfo[s].displayTwoType?.name) {
              case "large":
                numberOfUsedDevicesInSubProject.numberOfLargeDisplay += 1;
                break;
              case "medium":
                numberOfUsedDevicesInSubProject.numberOfMediumDisplay += 1;
                break;
              case "small":
                numberOfUsedDevicesInSubProject.numberOfSmallDisplay += 1;
                break;
            }
          }
          break;
        case "measure":
          if (sectionInfo[s].boardTwo || sectionInfo[s].boardOne) numberOfUsedDevicesInSubProject.numberOfMeasure += 1;
          break;
        case "camera":
          if (sectionInfo[s].boardOne) numberOfUsedDevicesInSubProject.numberOfCamera += 1;
          break;
        default:
          break;
      }
    }

    return numberOfUsedDevicesInSubProject;
  }

  buildSubProjectRowEntry(
    subProjectInfo: any,
    numberOfUsedDevicesInSubProject: NumberOfUsedDevicesInProject,
    subProjectRowEntryBody: RowInput[]
  ) {
    let deviceInfo = "";
    if (numberOfUsedDevicesInSubProject.numberOfLargeDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInSubProject.numberOfLargeDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.large")}-64x96px\n`;
    if (numberOfUsedDevicesInSubProject.numberOfMediumDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInSubProject.numberOfMediumDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.medium")}-48x80px\n`;
    if (numberOfUsedDevicesInSubProject.numberOfSmallDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInSubProject.numberOfSmallDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.small")}-48x48px\n`;
    if (numberOfUsedDevicesInSubProject.numberOfMeasure > 0)
      deviceInfo += `${numberOfUsedDevicesInSubProject.numberOfMeasure}xMQ-${i18n.t(
        "projectReport.sectionOptions.radar"
      )}\n`;
    if (numberOfUsedDevicesInSubProject.numberOfCamera > 0)
      deviceInfo += `${numberOfUsedDevicesInSubProject.numberOfCamera}x${i18n.t(
        "projectReport.sectionOptions.camera"
      )}`;

    if (subProjectInfo.type && subProjectInfo.status) {
      subProjectRowEntryBody.push([
        subProjectInfo.name,
        i18n.t(`projects.types.${subProjectInfo.type?.name}`),
        i18n.t(`project.statusOption.${subProjectInfo.status?.name}`),
        i18n.d(new Date(subProjectInfo.dateStart), "rep", "de") + " Uhr",
        i18n.d(new Date(subProjectInfo.dateEnd), "rep", "de") + " Uhr",
        deviceInfo
      ]);
    }

    return subProjectRowEntryBody;
  }

  calculateNumberOfUsedDevicesInMainProject(
    numberOfUsedDevicesInMainProject: NumberOfUsedDevicesInProject,
    numberOfUsedDevicesInSubProject: NumberOfUsedDevicesInProject
  ) {
    numberOfUsedDevicesInMainProject.numberOfLargeDisplay += numberOfUsedDevicesInSubProject.numberOfLargeDisplay;
    numberOfUsedDevicesInMainProject.numberOfMediumDisplay += numberOfUsedDevicesInSubProject.numberOfMediumDisplay;
    numberOfUsedDevicesInMainProject.numberOfSmallDisplay += numberOfUsedDevicesInSubProject.numberOfSmallDisplay;
    numberOfUsedDevicesInMainProject.numberOfMeasure += numberOfUsedDevicesInSubProject.numberOfMeasure;
    numberOfUsedDevicesInMainProject.numberOfCamera += numberOfUsedDevicesInSubProject.numberOfCamera;
    return numberOfUsedDevicesInMainProject;
  }

  buildMainProjectStatsTable(
    mainProject: any,
    numberOfUsedDevicesInMainProject: NumberOfUsedDevicesInProject,
    body: RowInput[]
  ) {
    let deviceInfo = "";
    let mainProjectStatsTableFooter: RowInput[] = []; // Table footer

    if (numberOfUsedDevicesInMainProject.numberOfLargeDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInMainProject.numberOfLargeDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.large")}-64x96px\n`;
    if (numberOfUsedDevicesInMainProject.numberOfMediumDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInMainProject.numberOfMediumDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.medium")}-48x80px\n`;
    if (numberOfUsedDevicesInMainProject.numberOfSmallDisplay > 0)
      deviceInfo += `${numberOfUsedDevicesInMainProject.numberOfSmallDisplay}xLED-${i18n.t(
        "projectReport.sectionOptions.display"
      )}-${i18n.t("projectReport.displayTypesOption.small")}-48x48px\n`;
    if (numberOfUsedDevicesInMainProject.numberOfMeasure > 0)
      deviceInfo += `${numberOfUsedDevicesInMainProject.numberOfMeasure}xMQ-${i18n.t(
        "projectReport.sectionOptions.radar"
      )}\n`;
    if (numberOfUsedDevicesInMainProject.numberOfCamera > 0)
      deviceInfo += `${numberOfUsedDevicesInMainProject.numberOfCamera}x${i18n.t(
        "projectReport.sectionOptions.camera"
      )}`;

    if (mainProject.type && mainProject.status) {
      mainProjectStatsTableFooter = [
        [
          {
            content: i18n.t("projectReport.summary").toString(),
            colSpan: 2
          },
          i18n.t("project.statusOption." + mainProject.status?.name),
          i18n.d(new Date(mainProject.dateStart), "rep", "de") + " Uhr",
          i18n.d(new Date(mainProject.dateEnd), "rep", "de") + " Uhr",
          deviceInfo
        ]
      ];
    }

    const mainProjectStatsTableData: TableStructureData = {
      tableTitle: mainProject.name,
      tableHeader: [
        [
          i18n.t("projectReport.tablelabel.name"),
          i18n.t("projectReport.tablelabel.type"),
          i18n.t("projectReport.tablelabel.status"),
          i18n.t("projectReport.tablelabel.startDate"),
          i18n.t("projectReport.tablelabel.endDate"),
          i18n.t("projectReport.tablelabel.devices")
        ]
      ],
      tableBody: body,
      tableFooter: mainProjectStatsTableFooter
    };

    return mainProjectStatsTableData;
  }

  async drawTable(pdf: jsPDF, tableData: TableStructureData, reportType: string, tableType: string, projectInfo?: any) {
    let tableStartY: number;

    if ((pdf as any).lastAutoTable.finalY && (pdf as any).lastAutoTable.finalY > pdf.internal.pageSize.height - 3.4) {
      if (tableType !== "sectionProfiles") pdf.addPage();
      if (reportType !== "projectList") this.drawPageForm(pdf, reportType, projectInfo);
      tableStartY = 1;
    } else {
      tableStartY =
        (pdf as any).lastAutoTable.finalY && tableType !== "sectionProfiles"
          ? (pdf as any).lastAutoTable.finalY + 1
          : 1;
    }

    const header = () => {
      if (pdf && reportType !== "projectList") {
        this.drawText(pdf, tableData.tableTitle, 0, 20, "bold", 0.8, tableStartY, "");
      }
    };

    const imagesArray = ["40", "60", "80", "100", "120", "Stau", "Stgefahr", ""];

    if (reportType === "projectList") this.drawText(pdf, tableData.tableTitle, 0, 20, "bold", 0.8, tableStartY, "");

    autoTable(pdf, {
      head: tableData.tableHeader,
      body: tableData.tableBody,
      foot: tableData.tableFooter,
      startY: tableStartY + 0.4,
      margin: { top: 1.2, bottom: 1 },
      pageBreak: "auto",
      rowPageBreak: "auto",
      styles: { fontSize: 8, textColor: 0 },
      headStyles: { fillColor: [49, 124, 60], textColor: 255 },
      footStyles: { fillColor: 220, fontSize: 9, fontStyle: "bold" },
      columnStyles:
        tableType === "projectStat"
          ? {
              0: {
                cellWidth: 1.4
              },
              1: {
                cellWidth: 1.5
              },
              2: {
                cellWidth: 0.7
              },
              3: {
                cellWidth: 0.9
              },
              4: {
                cellWidth: 0.9
              }
            }
          : tableType === "intervals"
          ? {
              0: {
                cellWidth: 1
              },
              1: {
                cellWidth: 1
              },
              2: {
                cellWidth: 1
              },
              3: {
                cellWidth: 1
              },
              4: {
                cellWidth: 1.5
              }
            }
          : {
              0: {
                cellWidth: 1.5
              },
              1: {
                cellWidth: 1.5
              },
              2: {
                cellWidth: 2.5
              }
            },
      showFoot: reportType === "projectList" ? "lastPage" : "never",
      didDrawPage: header,
      didDrawCell:
        reportType === "profileList"
          ? (data) => {
              if (data.section === "body" && data.column.index === 5) {
                if (imagesArray.indexOf(data.cell.text[0]) > -1) {
                  const displayCharacterImage = new Image();
                  displayCharacterImage.src =
                    data.cell.text[0] === "" ? `/img/48x48/noPicture.jpg` : `/img/48x48/${data.cell.text[0]}.bmp`;
                  pdf.addImage(displayCharacterImage, "png", data.cell.x, data.cell.y, 0.25, 0.25);
                }
              }
            }
          : () => {},
      didParseCell:
        reportType === "profileList"
          ? function (data) {
              if (data.section === "body" && data.column.index === 5) {
                if (imagesArray.indexOf(data.cell.text[0]) > -1) {
                  data.cell.styles.fontSize = 1;
                }
              }
            }
          : () => {}
    });
    return pdf;
  }
}
