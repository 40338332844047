/***
 * Mixing that have the format the kilometric to 3
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

import Section from "@/model/ModelSection";

@Component({
  computed: {
    ...mapGetters(["isUserAdmin", "isUserAgency"])
  }
})
export default class KmFormatted extends Vue {
  /*----------  Prop for this component  ----------*/
  @Prop({ type: Object, default: () => ({}) }) schematicInformation!: Section;

  /*----------  Vuex action and getters  ----------*/
  readonly isUserAdmin?: boolean;
  readonly isUserAgency!: boolean;

  /*----------  Methods  ----------*/
  formattedPosition(km: string) {
    if (km.length > 11) return `km ${km.slice(0, 11)}...`;
    return `km ${km}`;
  }
}
