
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import Project from "@/model/Project";
import Section from "@/model/ModelSection";
import PdfReport from "@/model/ModelPdfReport";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import FileSaver from "file-saver";
// @ts-ignore
import JSZip from "jszip/dist/jszip";

interface SectionInfo {
  Name: string;
  Kilometer: string;
  IDLinkeSeite: string;
  IDRechteSeite: string;
}
interface CSV {
  csvBlob?: any;
  name?: string;
  csvBlob2?: any;
  name2?: string;
}
@Component({
  name: "PdfReportModal",
  computed: {
    ...mapGetters("projects", ["GetCurrentProject", "GetSectionList", "GetAllOppositeSections"]),
    ...mapGetters("ProjectReportGenerator", ["GetGeneratedReport"])
  },
  methods: {
    ...mapActions("ProjectReportGenerator", ["GET_PROJECT_REPORT"])
  }
})
export default class PdfReportModal extends Vue {
  @Prop({ type: String, default: "project_report" }) reportType!: String;
  @Prop({ default: [] }) report!: any;
  @Prop({ default: "" }) reportName!: string;
  @Prop({ default: "" }) reportStartDate!: string;
  @Prop({ default: "" }) reportEndDate!: string;

  private readonly GetCurrentProject!: Project;
  PdfReport = new PdfReport();
  private readonly GetGeneratedReport!: CSV;
  private GET_PROJECT_REPORT!: Function;
  private readonly GetSectionList!: Array<Section>;
  // Get all Opposite section of road zipper project
  private readonly GetAllOppositeSections!: Function;

  // section info table
  sectionInfoFieldsPdfReport = ["Name", "Kilometer", "ID Linke Seite", "ID Rechte Seite"];
  sectionInfoItemsPdfReport: SectionInfo[] | any[] = [];
  oppositeSectionInfoItemsPdfReport: SectionInfo[] | any[] = []; // road zipper opposite sections
  waitingPdfPreparing = false;

  get sectionInfoFieldsPdfReportGet() {
    if (this.GetCurrentProject.isRZP)
      return ["FR " + this.GetCurrentProject.directionNameEnd, "Kilometer", "ID Linke Seite", "ID Rechte Seite"];
    else return this.sectionInfoFieldsPdfReport;
  }

  get oppositeSectionInfoFieldsPdfReportGet() {
    if (this.GetCurrentProject.isRZP)
      return ["FR " + this.GetCurrentProject.directionNameStart, "Kilometer", "ID Linke Seite", "ID Rechte Seite"];
    else return this.sectionInfoFieldsPdfReport;
  }

  async generatePdfReport(pdf: jsPDF | any) {
    const projectInfo: any = {
      projectName: this.GetCurrentProject.name,
      projectType: this.GetCurrentProject.type?.name,
      directionNameStart: this.GetCurrentProject.directionNameStart,
      directionNameEnd: this.GetCurrentProject.directionNameEnd
    };
    const projectDirections = this.GetCurrentProject.isRZP
      ? `${this.GetCurrentProject.directionNameStart}/${this.GetCurrentProject.directionNameEnd}`
      : `${this.GetCurrentProject.directionNameStart}->${this.GetCurrentProject.directionNameEnd}`;

    const projectInfoValues = [
      this.GetCurrentProject.name,
      projectDirections,
      `${this.$t("project.projectTypes." + this.GetCurrentProject.type?.name)}`,
      `${this.$d(new Date(this.reportStartDate), "rep", "de")} Uhr bis`,
      `${this.$d(new Date(this.reportEndDate), "rep", "de")} Uhr`
    ];
    // Page 1 Project info
    pdf = await this.PdfReport.drawFirstPagePdfReport(pdf, projectInfoValues, projectInfo, "switchingStates");

    // Page 2 Section info
    if (
      !(
        this.GetCurrentProject.type?.name === "free_programmable" ||
        this.GetCurrentProject.type?.name === "variable_message_signs"
      )
    ) {
      // Draw section Tabel for MSWA and Roadzipper projects
      // For Road zipper projects: put the table of opposite sections before the direct ones
      pdf.addPage();
      pdf.setPage(2);

      if (this.GetCurrentProject.isSCENE) {
        // Draw header, background and footer
        this.PdfReport.drawPageForm(pdf, "switchingStates", projectInfo);
      }
      // section table
      // Title of section table
      var sectionInfoTitle = "Aufbau der Anlage";
      this.PdfReport.drawText(pdf, sectionInfoTitle, 0, 20, "bold", 0.5, 0.8, "");

      let locationOfSection = 1;
      if (this.GetCurrentProject.isRZP) locationOfSection = 4.5;
      var fonSize = this.GetCurrentProject.isSCENE ? 5 : 7;
      autoTable(pdf, {
        html: "#sectionInfoTeble",
        startY: locationOfSection,
        theme: "striped",
        tableWidth: "auto",
        styles: { fontSize: fonSize, textColor: 0, lineWidth: 0.01, minCellWidth: 0.4 },
        columnStyles: { 0: { fillColor: [49, 124, 60], textColor: 255 } },
        didParseCell(data) {
          if (data.row.index === 0) {
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fillColor = [49, 124, 60];
          }
        }
      });
      if (this.GetCurrentProject.isRZP) {
        // Draw header, background and footer
        this.PdfReport.drawPageForm(pdf, "switchingStates", projectInfo);

        this.sectionInfoFieldsPdfReport = [
          this.GetCurrentProject.directionNameStart,
          "Kilometer",
          "ID Linke Seite",
          "ID Rechte Seite"
        ];
        autoTable(pdf, {
          html: "#oppositeSectionInfoTeble",
          startY: 1.5,
          theme: "striped",
          styles: { fontSize: 7, textColor: 0, lineWidth: 0.01 },
          columnStyles: { 0: { fillColor: [49, 124, 60], textColor: 255 } },
          didParseCell(data) {
            if (data.row.index === 0) {
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [49, 124, 60];
            }
          }
        });
      }
    }

    // Page 2 or Page 3 & 4 (Road zipper project) Display characters
    if (this.GetCurrentProject.isRZP) {
      pdf = await this.PdfReport.drawDisplayCharacters(
        pdf,
        projectInfo,
        "switchingStates",
        this.GetSectionList,
        this.GetAllOppositeSections
      );
    } else if (!this.GetCurrentProject.isSCENE) {
      pdf = await this.PdfReport.drawDisplayCharacters(pdf, projectInfo, "switchingStates", this.GetSectionList);
    }

    // From Page 4 or from Page 5 (Road zipper project)
    pdf = await this.PdfReport.drawTablePagesPdfReport(pdf, projectInfo, "switchingStates");

    // Draw page number
    pdf = await this.PdfReport.drawPageNumber(pdf);

    return pdf;
  }

  async downloadZip() {
    let zip = new JSZip();
    let zipFileName = this.GetGeneratedReport.name;
    if (this.reportType !== "traffic_count") {
      zip.file(this.GetGeneratedReport.name, this.GetGeneratedReport.csvBlob);
      this.waitingPdfPreparing = true;

      let pdf: any | jsPDF;
      pdf = new jsPDF({ unit: "in", orientation: "portrait" });

      let projectType = this.GetCurrentProject.type?.name;

      let pdfInfo: any;
      if (this.GetCurrentProject.isRZP) {
        pdfInfo = await this.PdfReport.preparePdfReport(
          this.report,
          projectType,
          this.GetSectionList,
          this.GetAllOppositeSections
        );
        this.oppositeSectionInfoItemsPdfReport = pdfInfo[0].oppositeSectionInfo;
      } else pdfInfo = await this.PdfReport.preparePdfReport(this.report, projectType, this.GetSectionList);

      this.sectionInfoItemsPdfReport = pdfInfo[0].sectionInfo;

      // call pdf creator
      pdf = await this.generatePdfReport(pdf);

      zip.file(`${this.reportName}.pdf`, pdf.output("blob"));
      zipFileName = this.reportName;
    } else {
      zip.file(`${this.GetGeneratedReport.name}.csv`, this.GetGeneratedReport.csvBlob);
      zip.file(`${this.GetGeneratedReport.name2}.csv`, this.GetGeneratedReport.csvBlob2);
    }

    zip.generateAsync({ type: "blob" }).then((content: any) => {
      FileSaver.saveAs(content, `${zipFileName}.zip`);
      this.waitingPdfPreparing = false;
      this.PdfReport.switchingStateItemsPdfReport = [];
      this.PdfReport.sectionInfoItemsPdfReport = [];
      this.$emit("toggle");
    });
  }
}
