
/**
 * Report generator modal
 * to generate a report we will
 * need the start date to generate the report
 * the end date for the generated report
 * and the project id
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface LocalTime {
  start: string;
  end: string;
}
interface CSV {
  csvBlob?: any;
  name: string;
}
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { ReportOptions } from "@/interface/projectsInterface";
import Project from "@/model/Project";
import { BButton } from "bootstrap-vue";
import DateToIsoString from "@/utils/DateToIsoString";
import { TranslateResult } from "vue-i18n";
import Section from "@/model/ModelSection";
// imports for pdf report
import PdfReportModal from "@/components/project/SingleProject/Report/PdfReportModal.vue";

@Component({
  name: "ReportGeneratorModal",
  components: { PdfReportModal },
  computed: {
    ...mapGetters("projects", ["GetCurrentProject", "GetSectionList"]),
    ...mapGetters("ProjectReportGenerator", ["GetGeneratedReport"])
  },
  methods: {
    ...mapMutations("ProjectReportGenerator", ["SetProjectReport", "SetMeasurementSection_Report"]),
    ...mapActions("ProjectReportGenerator", ["GET_PROJECT_REPORT", "GET_MEASURESECTION_REPORT"])
  }
})
export default class ReportGeneratorModal extends Vue {
  public $refs!: {
    submit: BButton;
    dialog: HTMLElement;
  };

  /******Props *****/
  @Prop({ type: String, default: "project_report" }) reportType!: String;
  @Prop({}) projectId!: any;
  @Prop({}) sectionId!: any;
  @Prop({}) boardId!: any;
  /******Vuex *****/
  private readonly GetCurrentProject!: Project;
  private readonly GetSectionList!: Array<Section>;
  private readonly SetProjectReport!: Function;
  private readonly GetGeneratedReport!: CSV;
  private GET_PROJECT_REPORT!: Function;
  private GET_MEASURESECTION_REPORT!: Function;
  /******localState *****/
  report: ReportOptions = {
    startDate: "",
    endDate: "",
    projectId: "",
    sectionId: "",
    boardId: ""
  };
  context: null | object = null;
  localTime: LocalTime = {
    start: "",
    end: ""
  };
  translationLocal: string = "de";

  busy = false;
  processing = false;

  localMessage: TranslateResult = "";
  errorMessage: TranslateResult | string = "";

  downloadReportLink: boolean = false;
  reportName = "Report";
  generatingReport: any;

  /*----------  Vue lifecycle  ----------*/
  mounted() {
    this.report.projectId = this.$route.params.id ? this.$route.params.id : this.projectId;
    this.localMessage = this.$t("projectReport.modal.defaultMessage");
    this.translationLocal = this.$i18n.locale;
  }
  beforeDestroy() {
    this.onReset();
  }

  /*----------  Computed  ----------*/
  get minDate() {
    return new Date(this.GetCurrentProject.dateStart);
  }

  get maxDate() {
    return new Date(this.GetCurrentProject.dateEnd);
  }
  get minTime() {
    return this.minDate.getHours();
  }
  get formValid() {
    return this.report.startDate && this.report.endDate && this.report.projectId;
  }

  /*****  Methods ******/
  generateReport() {
    this.processing = false;
    this.busy = true;
    if (!this.localTime.start || !this.localTime.end) {
      this.localTime.start = this.localTime.start ? this.localTime.start : "00:00:00";
      this.localTime.end = this.localTime.end ? this.localTime.end : "23:59:59";
    }
    let translation = "trafficCountReport";
    if (this.reportType === "project_report") {
      translation = "projectReport";
    }
    this.localMessage = this.$t(`${translation}.modal.defaultTime`, {
      from: this.$d(new Date(new Date(`${this.report.startDate} ${this.localTime.start}`)), "med"),
      to: this.$d(new Date(`${this.report.endDate} ${this.localTime.end}`), "med")
    });
  }
  onReset() {
    this.report = {
      startDate: "",
      endDate: "",
      projectId: this.$route.params.id
    };
    this.localTime = {
      start: "",
      end: ""
    };
    this.localMessage =
      this.reportType === "project_report"
        ? this.$t("projectReport.modal.defaultMessage")
        : this.$t("trafficCountReport.modal.defaultMessage");
  }
  onContext(ctx: object) {
    this.context = ctx;
  }

  onShown() {
    this.$refs.dialog.focus();
  }
  onHidden() {
    this.$refs.submit.focus();
  }
  onCancel() {
    this.busy = false;
    this.errorMessage = "";
  }

  async handleGenerateReport() {
    this.processing = true;
    this.errorMessage = "";
    //Save the user selected date if the request fail we can reset to the old value
    let localStartDate = this.report.startDate;
    let localEndDate = this.report.endDate;

    // Format the start date and end date in iso format
    this.report.startDate = DateToIsoString(new Date(`${this.report.startDate} ${this.localTime.start}`));
    this.report.endDate = DateToIsoString(new Date(`${this.report.endDate} ${this.localTime.end}`));

    //
    if (this.reportType === "traffic_count") {
      this.report.sectionId = this.sectionId;
      this.report.boardId = this.boardId;
      this.generatingReport = await this.GET_MEASURESECTION_REPORT(this.report);
    } else {
      this.generatingReport = await this.GET_PROJECT_REPORT(this.report);
    }

    let nameStart = this.$d(new Date(`${localStartDate} ${this.localTime.start}`), "med");
    let nameEnd = this.$d(new Date(`${localEndDate} ${this.localTime.end}`), "med");

    if (this.generatingReport.status === 202) {
      this.downloadReportLink = true;
      this.reportName = this.GetGeneratedReport.name.slice(0, -4);
      // this.onReset();
      this.report.startDate = new Date(`${localStartDate} ${this.localTime.start}`);
      this.report.endDate = new Date(`${localEndDate} ${this.localTime.end}`);
    } else {
      this.report.startDate = localStartDate;
      this.report.endDate = localEndDate;
      this.errorMessage = this.$t("projectReport.modal.ErrorMessage", {
        start: nameStart,
        end: nameEnd
      });
    }
    this.processing = false;
    this.busy = false;
  }

  resetAll() {
    this.onReset();
    this.onCancel();
    this.SetProjectReport({});
    this.downloadReportLink = false;
    this.$bvModal.hide("reportGeneratorModal");
  }
}
